/* compiled from /scss/style.scss */

@charset "UTF-8";

/* ================================================== *
 *    compass
 * ================================================== */

// @import "compass/reset";
// @import "compass/css3";
// @import "compass/utilities/general";
// @import "compass/utilities/sprites";

@import "reset";
@import "variables";
@import "mixins";
@import "flexbox";

//$replace: sprite-map("replace/*.png", $spacing:50px);
//$replace-img: sprite-url($replace);

* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  height: 100%;
}
body {
  @include fs(14);
  letter-spacing: 1px;
  color: white;
  background-color: $black;
  font-family: 'Open Sans', sans-serif;
  //font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Noto Sans Japanese", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Arial, Meiryo, sans-serif;
  font-weight: 400;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}
strong {
  font-weight: bold;
}
label {
  cursor: pointer;
}
input,select,textarea,label,a {
  outline: none;
}
a {
  color: white;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.5s ease;
  &:hover {
    text-decoration: none;
  }
}
button {
  outline: none;
  cursor: pointer;
}
sup,
sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  position: relative;
  font-size: 0.75em;
}
sup {
  bottom: .75ex;
}
sub {
  top: .5ex;
}
img {
  width: auto;
  height: auto;
}
.hidden-pc {
  @media (min-width: $screen-sm-min) {
    display: none;
  }
}
.hidden-sp {
  @media (max-width: $screen-sm-min) {
    display: none;
  }
}
.container {
  margin-right: auto;
  margin-left: auto;
  @include mq(pc) {
    width: $container;
  }
}
#wrapper {
  position: relative;
}
#loader {
  position: fixed;
  background-color: $black;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  i {
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 50%;
    margin-left: -25px;
    margin-top: -35px;
    width: 50px;
    height: 50px;
    border: 2px solid white;
    border-radius: 50%;
    animation: spin 0.75s infinite linear;
    border-color: transparent;
    border-top-color: white;
    animation-duration: 5s;
    &:before, &:after {
      left: -2px;
      top: -2px;
      display: none;
      position: absolute;
      content: '';
      width: inherit;
      height: inherit;
      border: inherit;
      border-radius: inherit;
    }
    &:after {
      display: block;
      border-color: transparent;
      border-top-color: white;
      animation: spin 0.75s infinite ease-in-out alternate;
    }
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#menuButton {
	position: fixed;
	top:26px;
	right: 20px;
	width: 20px;
	height: 14px;
	cursor: pointer;
	z-index: 999;
  @include hoverOp;
  @include mq {
	  top:44px;
    right: 40px;
  	width: 24px;
  	height: 16px;
  }
  @include mq(pc) {
    right: 50px;
  }
	div {
		position: absolute;
		right: 0;
		width: 20px;
		height: 2px;
		background: white;
		-webkit-transition: -webkit-transform .2s ease, background .2s ease .2s, top .2s ease .2s;
		transition: transform .2s ease, background .2s ease .2s, top .2s ease .2s;
    @include mq {
      width: 24px;
    }
		&.line01 {
			top: 0;
		}
		&.line02 {
			top: 6px;
      @include mq {
        top: 8px;
      }
		}
		&.line03 {
			top: 12px;
      @include mq {
        top: 16px;
      }
		}
	}
	&.on {
		div {
			-webkit-transition: background .2s ease, top .2s ease, -webkit-transform .2s ease .2s;
			transition: background .2s ease, top .2s ease, transform .2s ease .2s;
			background: white;
			&.line01 {
				top: 6px;
				-webkit-transform: rotateZ(45deg);
				transform: rotateZ(45deg);
        @include mq {
          top: 8px;
        }
			}
			&.line02 {
				background: rgba(255, 255, 255, 0) !important;
			}
			&.line03 {
				top: 6px;
				-webkit-transform: rotateZ(-45deg);
				transform: rotateZ(-45deg);
        @include mq {
          top: 8px;
        }
			}
		}
	}
}
header {
  &.hide {
    opacity: 0;
  }
  h1 {
    position: fixed;
    top: 22px;
    left: 20px;
    z-index: 998;
    @include mq {
      top: 40px;
      left: 40px;
    }
    @include mq(pc) {
      left: 50px;
    }
    a {
      display: block;
      width: 121px;
      height: 22px;
      background: url('/img/logo.png') 0 0 no-repeat;
      background-size: 121px auto;
      @include hide-text;
      @include hoverOp;
    }
  }
}
#gnav {
  display: none;
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: $black;
  .inner {
    height: 100%;
    @include flexbox;
    @include flex-direction(column);
    @include align-items(flex-end);
    @include justify-content(center);
    text-align: right;
    padding: 35px 15px 30px;
    @include mq {
      padding: 35px 30px 40px;
    }
    nav {
      @include flex(1);
      @include flexbox;
      @include align-items(center);
      @include en;
      ul {
        margin-right: 5px;
        li {
          @include fs(16,16);
          letter-spacing: 2px;
          @include mq {
            @include fs(21,21);
          }
          a {
            display: inline-block;
            padding: 13px 0;
            position: relative;
            &:after {
              content: '';
              display: block;
              background-color: $gray;
              width: 0px;
              height: 1px;
              position: absolute;
              bottom: 10px;
              transition: width 0.5s ease;
            }
            @include mq(pc) {
              &:hover {
                color: $lightgray;
                &:after {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
    .sns {
      @include flexbox;
      li {
        margin-left: 2px;
        a {
          display: block;
          padding: 5px;
          @include fs(18,18);
          position: relative;
          @include mq(pc) {
            &:hover {
              color: $gray;
            }
          }
        }
      }
    }
  }
}
#home {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: $black;
  z-index: 1000;
  transition: transform 1s ease;
  &.close {
    transform: translateY(-100vh)
  }
  .inner {
    @include flexbox;
    @include justify-content(center);
    margin: 20vh auto 0;
    height: 50vh;
    width: 80%;
    @include mq() {
      @include justify-content(flex-start);
      width: 500px;
    }
    @include mq(pc) {
      width: 800px;
    }
    /*
    .logo {
      position: relative;
      margin-right: 50px;
      @include mq() {
        width: 30%;
        margin-right: 0;
      }
      @include mq(pc) {
        width: 30%;
      }
      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        @include mq(pc) {
          max-height: 374px;
        }
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $black;
        @include transition(opacity 1s ease);
      }
      &.off {
        &:after {
    opacity: 0;
        }
      }
    }
    */
    .lead {
      position: relative;
      text-align: center;
      margin: 0 auto;
      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        @include mq(pc) {
          max-height: 374px;
        }
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $black;
        transition: height 1s ease;
      }
      &.off {
        &:after {
          height: 0;
        }
      }
    }
  }
  .arrow {
    text-align: center;
    position: absolute;
    bottom: 30px;
    width: 100%;
    a {
      display: inline-block;
      position: relative;
      width: 54px;
      height: 30px;
      &:before, &:after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 11px;
        left: 0;
        width: 0px;
        height: 2px;
        background: white;
        -webkit-transition: -webkit-transform .2s ease, left .2s ease, right .2s ease, width 1s ease ;
        transition: transform .2s ease, left .2s ease, right .2s ease, width 1s ease ;
        transform:rotateZ(45deg);
      }
      &:after {
        transform:rotateZ(-45deg);
        right: 0;
        left: auto;
      }
      @include mq(pc) {
        &.up {
          &:before {
            transform:rotateZ(-45deg);
            //left: 11px;
          }
          &:after {
            transform:rotateZ(45deg);
            //right: 11px;
          }
        }
      }
    }
    &.off {
      a {
        &:before, &:after {
          //top: 11px;
          //left: 0;
          width: 32px;
          height: 2px;
        }
      }
    }
  }
}
#worksList {
  padding: 70px 10px 50px;
  @include mq {
    padding: 100px 25px 80px;
  }
  @include mq(pc) {
    padding: 170px 0 120px;
  }
  ul {
    @include mq {
      @include flexbox;
      @include flex-wrap(wrap);
    }
    li {
      margin-bottom: 30px;
      padding: 0 10px;
      @include mq {
        width: 50%;
        padding: 0 15px;
      }
      @include mq(pc) {
        width: 353px;
      }
      .anchor {
        display: block;
        position: relative;
        top: -60px;
        visibility: hidden;
        @include mq {
          top: -80px;
        }
      }
      a {
        display: block;
        text-align: center;
        color: $lightgray;
        figure {
          overflow: hidden;
          margin-bottom: 8px;
          img {
            transition: transform 0.5s ease;
            transform:scale(1);
            width: 100%;
            @include mq(pc) {
              width: 323px;
              height: 216px;
            }
          }
        }
        figcaption {
          text-align: center;
          display: inline-block;
          margin:0 auto;
          @include fs(13,21);
          @include en;
          position: relative;
          padding-bottom: 0px;
          transition:color 0.5s ease;
          @include mq {
            @include fs(14,22);
          }
          &:after {
            content: '';
            display: block;
            background-color: $gray;
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 0;
            transition:transform 0.5s ease;
            transform:scale(0,1);
          }
        }
        @include mq(pc) {
          &:hover {
            figure {
              img {
                transform:scale(1.1);
              }
            }
            figcaption {
              color: white;
              &:after {
                transform:scale(1,1);
              }
            }
          }
        }
      }
    }
  }
}
#thumbList {
  padding: 120px 12px 35px;
  @include mq {
    padding: 120px 30px 35px;
  }
  @include mq(pc) {
    padding: 180px 0 25px;
  }
  .container {
    @include mq(pc) {
      width: 1050px;
    }
  }
  .title {
    margin-bottom: 20px;
    @include mq {
      margin-bottom: 13px;
    }
    @include mq(pc) {
      margin-bottom: 80px;
    }
    h1 {
      text-align: center;
      @include ja;
      @include fs(18,26);
      @include mq {
        @include fs(21,28);
      }
    }
    p {
      text-align: center;
      @include fs(11,20);
      color: $gray;
      @include en;
      @include mq {
        @include fs(13,24);
      }
    }
  }
  .backTop {
    transition:opacity 0.5s ease;
    &.off {
      opacity: 0;
    }
    a {
      display: block;
      @include hoverOp;
      width: 22px;
      height: 22px;
      margin: 0 7px 15px auto;
      position: relative;
      @include hide-text;
      @include mq {
        width: 28px;
        height: 28px;
      }
      @include mq(pc) {
        margin: 0 10px 25px auto;
      }
      &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        top: 10px;
        width: 20px;
        height: 2px;
        background: white;
        transform:rotateZ(45deg);
        @include mq {
          top: 15px;
          width: 30px;
        }
      }
      &:after {
        transform:rotateZ(-45deg);
      }
    }
  }
  ul {
    li {
      margin-bottom: 12px;
      width: 50%;
      padding: 0 8px;
      @include mq(pc) {
        width: 350px;
        padding: 0 10px;
        margin-bottom: 16px;
      }
      a {
        display: block;
        text-align: center;
        color: $lightgray;
        figure {
          overflow: hidden;
          img {
            transition:transform 0.5s ease;
            transform:scale(1);
            width: 100%;
          }
        }
        @include mq(pc) {
          &:hover {
            figure {
              img {
                transform:scale(1.1);
              }
            }
          }
        }
      }
    }
  }
  #slider {
    figure {
      margin-bottom: 12px;
      width: 50%;
      padding: 0 8px;
      @include mq(pc) {
        width: 350px;
        padding: 0 10px;
        margin-bottom: 16px;
      }
      a {
        display: block;
        text-align: center;
        color: $lightgray;
        overflow: hidden;
        img {
          transition:transform 0.5s ease;
          transform:scale(1);
          width: 100%;
        }
        @include mq(pc) {
          &:hover {
            img {
              transform:scale(1.1);
            }
          }
        }
      }
    }
  }
}
#descriptions {
  padding: 0 20px 20px;
  @include mq {
    padding: 0 35px 30px;
  }
  @include mq(pc) {
    padding: 0 0 30px;
  }
  .container {
    border: 1px solid #272b2d;
    @include mq(pc) {
      width: 1030px;
    }
    &.on {
      background-color: #eef3f6;
      color: $black;
      h2 {
        i {
          &:before {
            background: $black;
            transform:rotateZ(-45deg);
          }
          &:after {
            background: $black;
            transform:rotateZ(45deg);
          }
        }
      }
    }
    h2 {
      @include fs(10,10);
      text-transform: uppercase;
      padding: 15px;
      text-align: center;
      cursor: pointer;
      @include mq(pc) {
        padding: 22px;
        @include fs(12,12);
      }
      i {
        display: inline-block;
        position: relative;
        margin-left: 7px;
        width: 17px;
        height: 20px;
        vertical-align: -5px;
        &:before, &:after {
          content: '';
          display: inline-block;
          position: absolute;
          top: 11px;
          left: 0;
          width: 10px;
          height: 1px;
          background: white;
          -webkit-transition: -webkit-transform .2s ease, background .2s ease .2s, top .2s ease .2s;
          transition: transform .2s ease, background .2s ease .2s, top .2s ease .2s;
          transform:rotateZ(45deg);
          @include mq(pc) {
            top: 10px;
          }
        }
        &:after {
          transform:rotateZ(-45deg);
          right: 0;
          left: auto;
        }
      }
    }
    .inner {
      display: none;
      padding: 20px 35px 0;
      @include mq(pc) {
        padding: 80px 120px 60px;
      }
      .unit {
        padding-bottom: 20px;
        @include ja;
        @include mq(pc) {
          padding-bottom: 40px;
        }
        &.en {
          @include en;
        }
        p {
          @include fs(11,22);
          padding-bottom: 1em;
          @include mq(pc) {
            @include fs(13,26);
          }
        }
      }
    }
  }
}
#backBottom {
  //background: red;
  margin-bottom: 20px;
  height: 76px;
  @include mq() {
    margin-bottom: 30px;
  }
  &.on {
    p {
      position: fixed;
      z-index: 2;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      background-color: $black;
    }
    &.static {
      p {
        position: static;
      }
    }
  }
  p {
    a {
      @include fixedCloseBtn;
    }
  }
}
footer {
  text-align: center;
  @include en;
  @include fs(9,9);
  color: $lightgray;
  padding: 0 0 50px;
  transition:opacity 0.5s ease;
  &.hide {
    opacity: 0;
  }
  @include mq {
    @include flexbox;
    @include justify-content(space-between);
    @include fs(11,11);
    padding: 0 40px 40px;
  }
  @include mq(pc) {
    padding: 0 50px 40px;
  }
  p {
    margin-bottom: 20px;
  }
  ul {
    @include flexbox;
    @include justify-content(center);
    li {
      margin: 0 10px;
      a {
        color: $lightgray;
        &:hover {
          color: white;
        }
        i {
          margin-right: 5px;
          @include mq {
            vertical-align: -1px;
          }
        }
      }
    }
  }
}
#contactList {
  padding: 125px 20px 100px;
  @include mq {
    padding: 190px 90px 145px;
  }
  @include mq(pc) {
    padding: 230px 0 210px;
  }
  .container {
    @include mq(pc) {
      width: 800px;
    }
    .title {
      text-align: center;
      padding-bottom: 45px;
      @include mq {
        padding-bottom: 75px;
      }
      h1 {
        @include en;
        @include fs(24,24);
        margin-bottom: 35px;
        @include mq {
          @include fs(30,30);
          margin-bottom: 50px;
        }
        @include mq(pc) {
          @include fs(32,32);
          margin-bottom: 55px;
        }
      }
      p {
        @include ja;
        @include fs(12,24);
        @include mq {
          @include fs(13,26);
        }
        @include mq(pc) {
          @include fs(14,28);
        }
      }
      .thanks {
        p {
          padding-bottom: 2em;
          text-align: left;
          @include mq {
            text-align: center;
          }
          br {
            display: none;
            @include mq {
              display: block;
            }
          }
        }
      }
    }
    dl {
      @include mq {
        border-bottom: 1px solid $lightgray;
        @include flexbox;
        @include flex-wrap(wrap);
      }
      dt {
        @include ja;
        @include fs(13,13);
        margin-bottom: 12px;
        @include mq {
          @include fs(14,14);
          margin-bottom: 0;
          border-top: 1px solid $lightgray;
          width: 25%;
          padding-top: 23px;
        }
        @include mq(pc) {
          @include fs(16,16);
          padding-top: 25px;
        }
      }
      dd {
        margin-bottom: 25px;;
        @include mq {
          margin-bottom: 0;
          border-top: 1px solid $lightgray;
          width: 75%;
          padding: 10px 0;
        }
        input,textarea,p {
          width: 100%;
          background-color: #384044;
          color: white;
          border: none;
          padding: 12px;
          @include fs(16,16);
          line-height: normal;
          @include ja;
          @include placeholderColor(white);
          transition: all 0.5s ease;
          border-radius:0;
          @include mq {
            @include fs(16,16);
            line-height: normal;
            @include placeholderColor(#3d3f41);
            background-color: transparent;
          }
          @include mq(pc) {
            @include fs(16,16);
            line-height: normal;
            &:hover {
              @include placeholderColor(white);
            }
          }
        }
        textarea {
          height: 215px;
          line-height: 1.8;
          @include mq(pc) {
            padding-top: 12px;
            height: 250px;
          }
        }
        p {
          line-height: 1.8;
          padding: 10px;
          &.error {
            background-color: red;
            padding: 2px 10px;
          }
        }
      }
    }
    .btn {
      margin-top: 40px;
      @include mq {
        margin-top: 50px;
      }
      button {
        @include fs(13,13);
        @include ja;
        background-color: #d5d5d5;
        border: none;
        display: block;
        margin: 0 auto;
        padding: 16px 40px;
        transition:all 0.5s ease;
        &.back {
          background-color: transparent;
          color: $lightgray;
          padding: 0;
          margin-top: 30px;
          @include fs(11,11);
          &:hover {
            background-color: transparent;
          }
          @include mq {
            @include fs(12,12);
          }
          @include mq(pc) {
            @include fs(14,14);
            margin-top: 40px;
          }
        }
        @include mq {
          @include fs(14,14);
          padding: 18px 40px;
        }
        @include mq(pc) {
          @include fs(16,16);
          &:hover {
            background-color: #384044;
            color: white;
          }
        }
      }
    }
  }
}
#aboutBody {
  padding: 125px 20px 100px;
  overflow: hidden;
  @include mq {
    padding: 190px 90px 145px;
  }
  @include mq(pc) {
    padding: 230px 0 210px;
  }
  .container {
    @include mq(pc) {
      width: 800px;
    }
    h1 {
      text-align: center;
      padding-bottom: 60px;
      @include en;
      @include fs(24,24);
      @include mq {
        @include fs(30,30);
        padding-bottom: 75px;
      }
      @include mq(pc) {
        @include fs(32,32);
        padding-bottom: 120px;
      }
    }
    h2 {
      @include en;
      @include fs(19,19);
      padding: 25px 0 40px;
      @include mq {
        @include fs(21,21);
        padding: 35px 0 45px;
      }
      @include mq(pc) {
        padding: 55px 0 60px;
      }
    }
    .photo {
      padding: 15px 0;
      @include mq {
        padding: 25px 0;
      }
      img {
        width: 100%;
      }
    }
    .unit {
      @include ja;
      @include fs(13,25);
      letter-spacing: 0;
      padding-bottom: 20px;
      &.en {
        @include en;
      }
      @include mq(pc) {
        @include fs(14,28);
        padding-bottom: 30px;
      }
      p {
        padding-bottom: 1.75em;
      }
      table {
        margin-bottom: 20px;
        th {
          vertical-align: top;
          min-width: 60px;
          white-space: nowrap;
        }
      }
    }
  }
}
#recruitBody {
  padding: 125px 20px 100px;
  @include mq {
    padding: 190px 90px 145px;
  }
  @include mq(pc) {
    padding: 230px 0 210px;
  }
  .container {
    @include mq(pc) {
      width: 800px;
    }
    h1 {
      text-align: center;
      padding-bottom: 60px;
      @include en;
      @include fs(24,24);
      @include mq {
        @include fs(30,30);
        padding-bottom: 75px;
      }
      @include mq(pc) {
        @include fs(32,32);
        padding-bottom: 120px;
      }
    }
    h2 {
      @include en;
      @include fs(15,25);
      padding: 25px 0 30px;
      &:first-of-type {
        padding-top: 0;
      }
      @include mq {
        @include fs(16,28);
        padding: 35px 0 35px;
      }
      @include mq(pc) {
        @include fs(18,32);
        padding: 35px 0 35px;
      }
    }
    .photo {
      padding: 15px 0;
      @include mq {
        padding: 25px 0;
      }
      img {
        width: 100%;
      }
    }
    .unit {
      @include ja;
      @include fs(13,25);
      letter-spacing: 0;
      padding-bottom: 20px;
      &.en {
        @include en;
        ul {
          li {
            &:before {
              content: '-';
              left: 4px;
            }
          }
        }
      }
      @include mq(pc) {
        @include fs(14,28);
        padding-bottom: 30px;
      }
      p {
        padding-bottom: 1.75em;
      }
      ul {
        padding-bottom: 1.75em;
        &.wide {
          li {
            margin-bottom: 2em;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        li {
          position: relative;
          padding-left: 1em;
          &:before {
            content: '・';
            position: absolute;
            display: block;
            left: 0;
            top: 0;
          }
        }
      }
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
#accessList {
  padding: 125px 20px 100px;
  @include mq {
    padding: 190px 90px 145px;
  }
  @include mq(pc) {
    padding: 230px 0 210px;
  }
  .container {
    @include mq(pc) {
      width: 800px;
    }
    h1 {
      text-align: center;
      padding-bottom: 70px;
      @include en;
      @include fs(24,24);
      @include mq {
        @include fs(30,30);
        padding-bottom: 75px;
      }
      @include mq(pc) {
        @include fs(32,32);
        padding-bottom: 95px;
      }
    }
    .unit {
      padding-bottom: 75px;
      @include mq {
        padding-bottom: 90px;
      }
      @include mq(pc) {
        padding-bottom: 110px;
      }
      &:last-of-type {
        padding-bottom: 0;
      }
      .mapArea {
        width: 100%;
        height: 320px;
        margin-bottom: 28px;
        @include mq {
          height: 450px;
          margin-bottom: 50px;
        }
        @include mq(pc) {
          height: 500px;
        }
      }
      h2 {
        text-align: center;
        @include en;
        @include fs(17,28);
        margin-bottom: 15px;
        @include mq {
          @include fs(19,32);
          margin-bottom: 20px;
        }
        @include mq(pc) {
          @include fs(20,34);
        }
      }
      address {
        text-align: center;
        @include ja;
        @include fs(12,24);
        @include mq {
          @include fs(13,26);
        }
        @include mq(pc) {
          @include fs(14,28);
        }
      }
    }
  }
}
#pageTop {
  @media (min-width: $screen-sm-min) {
    position: fixed;
    bottom: 90px;
    left: 50%;
    margin-left: 570px;
    display: none;
    opacity: 0;
    transition: opacity .2s linear;
    @media (min-width: $screen-sm-min) {
      display: block;
    }
    &.on {
      opacity: 1;
      position: fixed;
      bottom: 90px;
    }
  }
}
#memberList {
  padding: 125px 20px 100px;
  @include mq {
    padding: 190px 100px 145px;
  }
  @include mq(pc) {
    padding: 230px 0 210px;
  }
  .container {
    @include mq(pc) {
      width: 800px;
    }
    h1 {
      text-align: center;
      padding-bottom: 70px;
      @include en;
      @include fs(24,24);
      @include mq {
        @include fs(30,30);
        padding-bottom: 75px;
      }
      @include mq(pc) {
        @include fs(32,32);
        padding-bottom: 95px;
      }
    }
    h2 {
      text-align: center;
      padding-bottom: 45px;
      @include en;
      @include fs(20,20);
      @include mq {
        @include fs(24,24);
        padding-bottom: 70px;
      }
      @include mq(pc) {
        @include fs(26,26);
        padding-bottom: 70px;
      }
    }
    .list {
      padding-bottom: 30px;
      @include mq {
        display: flex;
        flex-wrap: wrap;
      }
      .unit {
        padding-bottom: 50px;
        @include mq {
          width: calc(50% - 15px);
          padding-bottom: 70px;
          &:nth-child(odd){
            margin-right: 30px;
          }
        }
        @include mq(pc) {
          width: calc(50% - 20px);
          padding-bottom: 70px;
          &:nth-child(odd){
            margin-right: 40px;
          }
        }
        .photo {
          img {
            width: 100%;
            &.sp {
              @include mq {
                display: none;
              }
            }
            &.pc {
              display: none;
              @include mq {
                display: block;
              }
            }
          }
        }
        .info {
          margin-top: 25px;
          @include mq(pc) {
            margin-top: 28px;
          }
          @include ja;
          .position {
            @include fs(11,18);
            padding-bottom: 14px;
            @include mq(pc) {
              padding-bottom: 12px;
              @include fs(13,23);
            }
          }
          h3 {
            @include fs(18,26);
            @include mq(pc) {
              @include fs(20,30);
            }
          }
          .en {
            @include en;
            opacity: .4;
            @include fs(14,22);
            letter-spacing: 0;
            @include mq(pc) {
              @include fs(15,24);
            }
          }
          .bio {
            margin-top: 18px;
            @include fs(13,26);
            @include mq(pc) {
              margin-top: 28px;
              @include fs(14,28);
            }
          }
        }
      }
    }
  }
}
@import "photoswipe";
