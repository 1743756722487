@import 'variables';

@mixin en {
  font-family:Garamond, 'Times New Roman', serif;
}
@mixin ja {
  font-family: '秀英明朝 M', 'Shuei Mincho M', '游明朝', YuMincho, 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN', 'HG明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;
}
@mixin mq($breakpoint: tb) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
@mixin placeholderColor($color) {
    &:placeholder-shown {
        color: $color;
        transition: all 0.5s ease;
    }
    &::-webkit-input-placeholder {
        color:$color;
        transition: all 0.5s ease;
    }
    &:-moz-placeholder {
        color:$color;
        opacity: 1;
        transition: all 0.5s ease;
    }
    &::-moz-placeholder {
        color:$color;
        opacity: 1;
        transition: all 0.5s ease;
    }
    &:-ms-input-placeholder {
        color:$color;
        transition: all 0.5s ease;
    }
}
@mixin fixedCloseBtn {
  cursor: pointer;
  height: 76px;
  position: relative;
  display: block;
  @include hide-text;
  width: 100%;
  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    top: 36px;
    left: 50%;
    margin-left: -11px;
    width: 20px;
    height: 2px;
    background: white;
    transform:rotateZ(45deg);
    @include mq {
      top: 36px;
      width: 30px;
    }
  }
  &:after {
    transform:rotateZ(-45deg);
  }
}
@mixin container {
  width: $container;
  margin: 0 auto;
}
@mixin hoverOp($op:0.5) {
  @include mq(pc) {
    transition: opacity 0.2s linear;
    -webkit-backface-visibility: hidden;
    &:hover {
      opacity: $op;
      text-decoration: inherit;
    }
  }
}
@mixin hide-text() {
  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;
}
@mixin fs($fts, $lin: 14) {
  $rem: $fts / 10;
  $linS: $lin / $fts;
  font-size: #{$fts}px;
  font-size: #{$rem}rem;
  line-height: $linS;
}
@mixin sprite-background-legacy($name) {
  width: image-width(sprite-file($replace, $name));
  height: image-height(sprite-file($replace, $name));
  $xpos: nth(sprite-position($replace, $name), 1);
  $ypos: nth(sprite-position($replace, $name), 2);
  background-image: $replace-img;
  background-position: $xpos $ypos;
  background-repeat:no-repeat;
  $wbgz: image-width(sprite-path($replace));
  @include background-size($wbgz auto);
  display: block;
}
@mixin sprite-background($name) {
  width: image-width(sprite-file($replace, $name)) / 2;
  height: image-height(sprite-file($replace, $name)) / 2;
  $xpos: nth(sprite-position($replace, $name), 1) / 2;
  $ypos: nth(sprite-position($replace, $name), 2) / 2;
  background-image: $replace-img;
  background-position: $xpos $ypos;
  background-repeat:no-repeat;
  $wbgz: round(image-width(sprite-path($replace)) / 2);
  @include background-size($wbgz auto);
}
@mixin sprite-replace-legacy($name) {
  @include sprite-background-legacy($name);
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
@mixin sprite-replace($name) {
  @include sprite-background($name);
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
@mixin arrowIcon($name:arrow_right) {
  position: relative;
  text-decoration: none;
  padding-left: 16px;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top:6px;
    left: 0;
    width: 9px;
    height: 11px;
    background: url('#{$imagePath}replace/#{$name}.png');
    background-size: 9px auto;
  }
  &:hover {
    text-decoration: underline;
  }
}
@mixin slickdots($width:10px,$height:10px) {
  text-align: center;
  li {
    display: inline-block;
    width: auto;
    padding: 0;
    margin: 0 10px;
    button {
      border: none;
      background-color: #b2b2b2;
      color: transparent;
      height: $height;
      width: $width;
      padding: 0;
      cursor: pointer;
      border-radius:5px;
    }
    &.slick-active {
      button {
        background-color: $red;
      }
    }
  }
}

@mixin full {
  margin-left: -10px;
  margin-right: -10px;
  @media (min-width: $screen-sm-min) {
    margin-left: auto;
    margin-right: auto;
  }
}
