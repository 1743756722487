$screen-sm-min: 740px;
$screen-sm-min: 740px;
$container: 1060px;

$breakpoints: (
  'sp': 'screen and (min-width: 400px)',
  'tb': 'screen and (min-width: 768px)',
  'pc': 'screen and (min-width: 1080px)',
  'xl': 'screen and (min-width: 1200px)',
) !default;

$black: #090f12;
$lightgray: #bfc8cd;
$gray: #61686c;

$imagePath: '/images/';
